import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Certifications from '../components/Certifications';
import Location from '../components/Location';

const About = ({ data }) => (
  <Layout>
    <SEO
      meta_title={data.prismicAbout.data.meta_title}
      meta_description={data.prismicAbout.data.meta_description}
      hero_image={data.prismicAbout.data.hero_image.url}
    />
    <Hero
      title={
        <div
          dangerouslySetInnerHTML={{
            __html: data.prismicAbout.data.hero_text.html,
          }}
        />
      }
      overlay="bg-black opacity-50"
      imagebg={data.prismicAbout.data.hero_image.localFile.childImageSharp.fluid}
    />
    <section className="bg-orange text-white p-0">
      <div className="container">
        <div className="flex flex-wrap md:-mx-6">
          <div className="w-full md:w-1/2 py-16 md:px-6">
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.content.html,
              }}
            />
          </div>
          <div className="w-full md:w-1/2 text-center md:px-6 flex justify-center">
            <h4 className="self-center">
              <span style={{ fontSize: '6em' }}>100+</span> <br />
              Combined Years of Experience
            </h4>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container">
        <Certifications />
        <div
          dangerouslySetInnerHTML={{
            __html: data.prismicAbout.data.certifications.html,
          }}
          className="certifications mt-8"
        />
      </div>
    </section>

    <Location />
  </Layout>
);

export default About;

export const query = graphql`
  query aboutPage {
    prismicAbout {
      uid
      id
      data {
        meta_title
        meta_description
        title {
          html
          text
        }
        content {
          html
        }
        certifications {
          html
        }
        hero_image {
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600, maxHeight: 600, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        hero_text {
          html
        }
      }
    }
  }
`;
