import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Certifications = ({ data }) => (
  <div className="flex flex-wrap justify-around items-center">
    <Img fixed={data.anabec.childImageSharp.fixed} className="m-2" />
    <Img fixed={data.cconnection.childImageSharp.fixed} className="m-2" />
    <Img fixed={data.iicrc.childImageSharp.fixed} className="m-2" />
    <Img fixed={data.trsca.childImageSharp.fixed} className="m-2" />
    <Img fixed={data.epa.childImageSharp.fixed} className="m-2" />
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        anabec: file(relativePath: { eq: "partners/anabec.png" }) {
          childImageSharp {
            fixed(height: 50, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        iicrc: file(relativePath: { eq: "partners/iicrc.png" }) {
          childImageSharp {
            fixed(height: 50, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        cconnection: file(relativePath: { eq: "partners/contractor-connection-logo.png" }) {
          childImageSharp {
            fixed(height: 50, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        iicrc: file(relativePath: { eq: "partners/iicrc.png" }) {
          childImageSharp {
            fixed(height: 50, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        trsca: file(relativePath: { eq: "partners/trsca-logo.png" }) {
          childImageSharp {
            fixed(height: 80, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        epa: file(relativePath: { eq: "partners/epa-certified.png" }) {
          childImageSharp {
            fixed(height: 80, grayscale: true) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => <Certifications data={data} {...props} />}
  />
);
