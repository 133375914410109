import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Location = ({ data }) => (
  <section className="p-0" style={{ backgroundColor: '#F2F0EB' }}>
    <div className="container">
      <div className="flex flex-wrap md:-mx-6">
        <div className="w-full md:w-1/2 py-16 md:px-6">
          <div
            dangerouslySetInnerHTML={{
              __html: data.prismicAbout.data.location_content.html,
            }}
          />
        </div>
        <div className="w-full md:w-1/2 md:px-6 flex">
          <Img fluid={data.map.childImageSharp.fluid} className="block w-full" alt="Service area Map" />
        </div>
      </div>
    </div>
  </section>
);

export default props => (
  <StaticQuery
    query={graphql`
      query location {
        prismicAbout {
          id
          data {
            location_content {
              html
            }
          }
        }
        map: file(relativePath: { eq: "structure_plus-map.jpg" }) {
          id
          publicURL
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <Location data={data} {...props} />}
  />
);
